import React from 'react';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';
import GiftItemIE from './IE/index';
import GiftItemUK from './UK/index';

const GiftItem = ({ ...props }) => {
  if (DEFAULT_COUNTRY_CODE === 'IE') {
    return <GiftItemIE {...props} />;
  } else {
    return <GiftItemUK {...props} />;
  }
};

export default GiftItem;
