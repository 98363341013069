import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import ProductPrice from '../../ProductPrice';
import AddToBasketButtonForTeasers from '../../AddToBasketButtonForTeasers';
import { CategoriesContext } from '../../../utils/context';

import DefaultPicture, {
  propTypes as pictureProps,
} from '../../../components/01_atoms/DefaultPicture';

import styles from './index.module.scss';

const GiftItem = ({ product, variant = 'default' }) => {
  const categories = useContext(CategoriesContext);

  const highlightedCategories = product.categoryIds.filter(
    (id) => categories[id]?.highlightedColor,
  );

  const image = product.tileImageUK ? product.tileImageUK : product.image;
  return (
    <div
      className={`${styles['gift-item']} ${styles[`gift-item-${variant}`]}`}
      data-test="gift-item"
    >
      <div className={styles['gift-item__content']}>
        <Link href={product.url.url}>
          <a>
            <div className={styles['gift-item__image']}>
              {image && <DefaultPicture {...image} loading="lazy" />}
            </div>
            <div className={styles['gift-item__details']}>
              <h3 className={styles['gift-item__title']} data-test="gift-item-title">
                {product.title}
              </h3>
              <div className={styles['gift-item__price']} data-test="gift-item-price">
                <ProductPrice product={product} />
              </div>
              {product.shortDescription && (
                <div className={styles['gift-item__description']}>{product.shortDescription}</div>
              )}
              {highlightedCategories && (
                <div className={styles['gift-item__highlighted']}>
                  {highlightedCategories.map((categoryId) => (
                    <span
                      key={categoryId}
                      className={
                        styles['gift-item__highlighted-' + categories[categoryId].highlightedColor]
                      }
                    >
                      {categories[categoryId].title}
                    </span>
                  ))}
                </div>
              )}
              {product.ecardActive && !product.postalCardActive && (
                <div className={styles['gift-item__ecard']}>E-Card only</div>
              )}
            </div>
          </a>
        </Link>
        <div className={styles['gift-item__button']}>
          <AddToBasketButtonForTeasers product={product} />
        </div>
      </div>
    </div>
  );
};

GiftItem.propTypes = {
  variant: PropTypes.oneOf(['default', 'catalog']),
  product: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    image: PropTypes.shape(pictureProps),
    actionImage: PropTypes.shape(pictureProps),
    tileImageUK: PropTypes.shape(pictureProps),
    url: PropTypes.object,
    shortDescription: PropTypes.string,
    ecardActive: PropTypes.bool,
    postalCardActive: PropTypes.bool,
  }).isRequired,
};

export default GiftItem;
