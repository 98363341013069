import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import ProductBadge from '../../ProductBadge';
import ProductPrice from '../../ProductPrice';
import AddToBasketButtonForTeasers from '../../AddToBasketButtonForTeasers';

import DefaultPicture from '../../../components/01_atoms/DefaultPicture';

import styles from './index.module.scss';

const GiftItem = ({ product, variant = 'default' }) => (
  <div className={`${styles['gift-item']} ${styles[`gift-item-${variant}`]}`} data-test="gift-item">
    <div className={styles['gift-item__content']}>
      <Link href={product.url.url}>
        <a>
          <div className={styles['gift-item__image']}>
            {product.image && <DefaultPicture {...product.image} loading="lazy" />}
            <ProductBadge product={product} />
          </div>
          <div className={styles['gift-item__details']}>
            <h3 className={styles['gift-item__title']} data-test="gift-item-title">{product.title}</h3>
            <div className={styles['gift-item__price']} data-test="gift-item-price">
              <ProductPrice product={product} />
            </div>
            {product.shortDescription && (
              <div className={styles['gift-item__description']}>{product.shortDescription}</div>
            )}
          </div>
        </a>
      </Link>
      <div className={styles['gift-item__button']}>
        <AddToBasketButtonForTeasers product={product} />
      </div>
    </div>
  </div>
);

GiftItem.propTypes = {
  variant: PropTypes.oneOf(['default', 'catalog']),
  product: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    image: PropTypes.object,
    url: PropTypes.object,
    shortDescription: PropTypes.string,
  }).isRequired,
};

export default GiftItem;
